import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonBarComponent } from './button-bar.component';
import { CommonImportsModule } from 'src/app/modules/app-imports/common-imports.module';
import { CommonMatImportsModule } from 'src/app/modules/app-imports/common-mat-imports.module';



@NgModule({
  declarations: [
    ButtonBarComponent
  ],
  imports: [
    CommonModule,
    CommonImportsModule,
    CommonMatImportsModule
  ],
  exports: [
    ButtonBarComponent
  ]
})
export class ButtonBarModule { }
