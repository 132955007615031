

<div class="container" [class.bottom-aligned]="position === 'bottom'">

	<div class="width-container">

		<div fxLayout="row" fxLayoutAlign="space-around stretch" class="bar">
			<ng-content select="button" fxFlex="1 1 0px"></ng-content>
		</div>

	</div>



</div>
