import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-button-bar',
  templateUrl: './button-bar.component.html',
  styleUrls: ['./button-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonBarComponent implements OnInit {

  constructor() { }

  @Input('position') position: 'inline' | 'bottom' = 'inline'

  ngOnInit(): void {
  }

}
